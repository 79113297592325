<template>
  <ion-content>
    <ion-list>
      <ion-list-header> {{ title }} </ion-list-header>
      <slot></slot>
      <ion-item lines="none" detail="false" button @click="dismissPopover"> Close </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonContent, IonList, IonListHeader, IonItem } from "@ionic/vue";

export default {
  props: {
    title: { type: String, default: "Options" },
  },
  emits: ["closePopover"],
  components: {
    IonContent,
    IonList,
    IonListHeader,
    IonItem,
  },

  methods: {
    dismissPopover() {
      this.$emit("closePopover");
    },
  },
};
</script>