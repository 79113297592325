<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-exam :resources="resources"></list-exam>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="setFilterModalState(true)">
        <ion-icon :ios="funnelOutline" :md="funnelOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ion-modal
      :is-open="filterModalState"
      @didDismiss="setFilterModalState(false)"
    >
      <base-modal
        title="Filter Subject"
        @closeModal="setFilterModalState(false)"
      >
        <filter-subject
          @closeModal="setFilterModalState(false)"
        ></filter-subject>
      </base-modal>
    </ion-modal> -->
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
// import BaseModal from "../../Base/BaseModal";
// import FilterSubject from "../../Components/Academic/Subject/FilterSubject.vue";
import ListExam from "../../Components/Exam/ListExam.vue";

import {
  IonGrid,
  IonRow,
  IonCol,
  // IonFab,
  // IonFabList,
  // IonIcon,
  // IonFabButton,
  // IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import {
  funnelOutline,
} from "ionicons/icons";

export default {
  components: {
    BaseLayout,
    // BaseModal,
    // FilterSubject,
    ListExam,
    IonGrid,
    IonRow,
    IonCol,
    // IonFab,
    // IonFabList,
    // IonIcon,
    // IonFabButton,
    // IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      title: "View Exams",
      backLink: "exam",
      reloadable: true,

      // createModalState: false,
      // filterModalState: false,

      funnelOutline,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["exam/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("exam/resources", { refresh: refresh});
    },
    async loadData(event) {
      await this.fetch();
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("exam/resources", { refresh: true });
      if (event) {
        event.target.complete();
      }
    },
    // setFilterModalState(state) {
    //   this.filterModalState = state;
    // },
  },

  async created() {
    await this.fetch();
  },
};
</script>

<style scoped>
</style>
